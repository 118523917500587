@use '@angular/material' as mat;
@use 'plantdb/theme';
@import "@angular/material/prebuilt-themes/deeppurple-amber.css";

/// ------- Material Colors -------

@include mat.all-legacy-component-themes(theme.$colors);

// Commenting this out in an attempt to fix "The same typography styles are generated multiple times."
// TODO: is it required (are styles still ok) ? is it the cause for the error mentioned above ?
// @include mat.all-legacy-component-typographies(theme.$typography);


//@include mat-base-typography($plant-typography);

/// ------- Site Styles -------

:root {
  --toolbar-height: 48px;
  --content-margin: 1rem;
  --content-padding: 1rem;
  --content-min-margin: 1rem;

  --content-max-width: 1340px;
  --breakpoint-max-width: 1380px;

  --card-width: 210px;    // catalogue plant list, and possible for other elements, too
  --gap: 0.75rem;   // catalogue plant list
  --gap-small: 0.25rem;   // other lists

  @media only screen and (min-width: theme.$breakpoint-tablet) {
    --content-margin: 3rem;
    --content-padding: 2rem;
    --content-min-margin: 3rem;
  }
}

*:focus {
  outline: none;
  border: none;
}

*::selection {
  background: mat.get-color-from-palette(theme.$primary, 900);
  color: mat.get-color-from-palette(theme.$accent);
}

html {
  scroll-behavior: smooth;
  -webkit-font-smoothing: subpixel-antialiased;
}

body {
  @include mat.typography-level(theme.$typography, body-2);
  font-optical-sizing: auto;
  background: mat.get-color-from-palette(theme.$primary, 100);
  //TODO -webkit-tap-highlight-color: mat-color(theme.$primary,900);
}

main {
  overflow-x: hidden;
}

article {
  margin-bottom: 4rem;
}

/**
 * Wrapper element around the main content
 * Positions the conteten by setting up margin.
 * Margin collapses for small screens.
 */
.page-inner {
  margin: 0 var(--content-min-margin);
  // overflow-x: hidden;
  @media only screen and (min-width: theme.$breakpoint-max) {
    max-width: var(--content-max-width);
    margin-left: auto;
    margin-right: auto;
  }

  &.main {
    padding-bottom: 1rem;
  }
}

// Margin for first page-inner element of main
main .page-inner:first-child {
  margin-top: var(--content-margin);
}

caption {
  @include mat.typography-level(theme.$typography, caption);
}

a {
  color: mat.get-color-from-palette(theme.$primary, 900);
  text-decoration: none;
}

h1,
h2,
h3,
h4 {
  @include mat.typography-level(theme.$typography, headline);
  color: mat.get-color-from-palette(theme.$primary, 900);
  // white-space: nowrap;
  //overflow: hidden;
  //text-overflow: ellipsis;
}

h1 {
  font-size: 4em;
  letter-spacing: -0.02em;
}

h2 {
  font-size: 3em;
  letter-spacing: -0.002em;
}

h3 {
  font-size: 2em;
  letter-spacing: 0;
  margin: 0.5em 0 ;
}

h4 {
  font-size: 1em;
  letter-spacing: 0;
}

main {
  a {
    text-decoration: none;
    border-bottom: 1px solid mat.get-color-from-palette(theme.$primary, 500);
    padding-bottom: 1px;
    transition: all .1s;
    &:hover {
      background: mat.get-color-from-palette(theme.$primary, 200);
      border-bottom: 1px solid mat.get-color-from-palette(theme.$primary, 600);
      transition: all .05s;
    }
  }
}


.meta {
  *::selection {
    background: var(--color-black);
    color: var(--color-white);
  }
}

/// -----

.material-icons {
  font-family: theme.$font-family-icons;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  font-feature-settings: 'liga';
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}


/// ------- Material Typography Tweaks -------

.mat-display-4 {
  font-variation-settings: 'wght'600;
}

.mat-display-3 {
  font-variation-settings: 'wght'650;
}

.mat-display-2 {
  font-variation-settings: 'wght'650;
}

.mat-display-1 {
  font-variation-settings: 'wght'100;
}

.mat-caption {
  font-variation-settings: 'wght'400;
  //text-transform: uppercase;
}

/// ------- Material Components Tweaks -------

.mat-input-element {
  max-width: 200px;
}

.mat-icon {
  user-select: none;
}

.mat-card {
  backface-visibility: hidden;
  outline: none;
  padding: 0 !important;
  @include mat.elevation(8);

  .mat-card-header {
    padding: 0;
  }

  .mat-card-header-text {
    margin: 0;
  }

  .mat-card-title {
    @include mat.typography-level(theme.$typography, display-1);
    font-variation-settings: 'wght'650;
  }

  .mat-card-subtitle {
    @include mat.typography-level(theme.$typography, subheading-2);
    font-variation-settings: 'wght'500;
  }
}

.mat-subheading-2 {
  @include mat.typography-level(theme.$typography, subheading-2);
  font-variation-settings: 'wght'500;
  color: mat.get-color-from-palette(theme.$primary, 500);
}

.mat-toolbar-row,
.mat-toolbar-single-row {
  height: 56px;
}


// large fab (actually, a sticky footer)
// TODO: better with flex etc.
.app-fab-section {
  position: fixed;

  left: 0;
  width: 99%;

  bottom: 0rem;
  height: 6rem;
  overflow: hidden;

  border-radius: 5px;

  // TODO: add background and elevation ?
  background: white;
  @include mat.elevation(4);

  // Inner alignments
  padding: 0.25rem 1rem;
  margin: 0.25rem;

  justify-content: space-between;

  // Only small screens
  display: none;
  @media only screen and (max-width: 800px) {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .button-combo {
    display: flex;
    gap: 10px;
    align-items: center;
  }

}

// normal fab
.app-fab-zone {
  text-align: center;
  position: fixed;
  bottom: 2.5rem;
  right: 2rem;

  // Only small screens
  display: none;
  @media only screen and (max-width: 800px) {
      display: block;
  }

}

.fab-info {
  margin: 0.25em 0;
}

/**
 * Add this to elements to make them hidden on small screens
 */
.hide-mobile {
  display: none;
  @media only screen and (min-width: theme.$breakpoint-phone) {
    display: initial;
  }
}