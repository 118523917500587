@use '@angular/material' as mat;

:root {
  --error-color: #e70000;
}

$breakpoint-phone: 400px;
$breakpoint-tablet: 800px;
$breakpoint-inter: 1200px;
$breakpoint-max: 1380px;

$icon-small: 12px;
$icon-mid: 24px;
$icon-large: 32px;
$icon-xlarge: 48px;
$icon-xxlarge: 96px;

$palette-primary: (
  50: #fafafa,
  100: #f5f5f5,
  200: #eeeeee,
  300: #e0e0e0,
  400: #bdbdbd,
  500: #9e9e9e,
  600: #757575,
  700: #616161,
  800: #424242,
  900: #212121,
  A100: #ffffff,
  A200: #eeeeee,
  A400: #bdbdbd,
  A700: #616161,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: white,
    A400: white,
    A700: white,
  )
);
$palette-accent: (
  50: #f1f8e9,
  100: #dcedc8,
  200: #c5e1a5,
  300: #aed581,
  400: #9ccc65,
  500: #8bc34a,
  600: #7cb342,
  700: #689f38,
  800: #558b2f,
  900: #33691e,
  A100: #ccff90,
  A200: #b2ff59,
  A400: #76ff03,
  A700: #64dd17,
  contrast: (
    50: rgba(black, 0.87),
    100: rgba(black, 0.87),
    200: rgba(black, 0.87),
    300: rgba(black, 0.87),
    400: rgba(black, 0.87),
    500: rgba(black, 0.87),
    600: rgba(black, 0.87),
    700: white,
    800: white,
    900: white,
    A100: rgba(black, 0.87),
    A200: rgba(black, 0.87),
    A400: rgba(black, 0.87),
    A700: rgba(black, 0.87),
  )
);

//TODO
$palette-meta: (
  50 : #e8e8e8,
  100 : #c6c6c6,
  200 : #a0a0a0,
  300 : #797979,
  400 : #5d5d5d,
  500 : #404040,
  600 : #3a3a3a,
  700 : #323232,
  800 : #2a2a2a,
  900 : #1c1c1c,
  A100 : #f07777,
  A200 : #eb4949,
  A400 : #ff0202,
  A700 : #e70000,
  contrast: (
      50 : #000000,
      100 : #000000,
      200 : #000000,
      300 : #ffffff,
      400 : #ffffff,
      500 : #ffffff,
      600 : #ffffff,
      700 : #ffffff,
      800 : #ffffff,
      900 : #ffffff,
      A100 : #000000,
      A200 : #ffffff,
      A400 : #ffffff,
      A700 : #ffffff,
  )
);

$primary: mat.define-palette($palette-primary);
$accent: mat.define-palette($palette-accent);
$warn: mat.define-palette(mat.$red-palette);


$meta-primary: mat.define-palette($palette-meta);
$meta: mat.define-light-theme($meta-primary);

$font-family-title: 'Bitter';
$font-family-regular: 'Roboto';
//$font-family-ui: "Inter";
$font-family-icons: "Material Icons";

$typography: mat.define-legacy-typography-config(
  $display-4:     mat.define-typography-level(112px, 112px, 500, $font-family-title, $letter-spacing: -0.05em),
  $display-3:     mat.define-typography-level(56px, 56px, 500, $font-family-title, $letter-spacing: -0.02em),
  $display-2:     mat.define-typography-level(40px, 48px, 500, $font-family-title, $letter-spacing: -0.005em),
  $display-1:     mat.define-typography-level(20px, 30px, 600, $font-family-title ),
  $headline:      mat.define-typography-level(48px, 48px, 700, $font-family-title, -0.05em ),
  $title:         mat.define-typography-level(32px, 32px, 700, $font-family-title, -0.05em ),
  $subheading-1:  mat.define-typography-level(16px, 16px, 500, $font-family-title ),
  $subheading-2:  mat.define-typography-level(14px, 18px, 500, $font-family-title ),
  $body-1:        mat.define-typography-level(16px, 24px, 400, $font-family-regular ),
  $body-2:        mat.define-typography-level(14px, 20px, 500, $font-family-regular ),
  $caption:       mat.define-typography-level(11px, 16px, 500, $font-family-regular ),
  $button:        mat.define-typography-level(14px, 14px, 500, $font-family-regular ),
  $input:         mat.define-typography-level( $font-family-regular, 1.125, 400)
);

$colors: mat.define-light-theme((
  color: (
    primary: $primary,
    accent: $accent,
    warn: $warn
  ),
  typography: $typography,
  density: 0
));