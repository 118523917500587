@use '@angular/material' as mat;
@use 'plantdb/theme';
/**
 * override angular material components
 */

 // to place it in the toolbaer-menu, and both are higher then the leaflet map layers
.cdk-overlay-container{
  z-index: 2000 !important;
}

.mdc-dialog__content {
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding: 1rem !important;
  max-height: 75vh !important;
}

.mat-mdc-dialog-title {
  .title {
    font-size: 1.5rem;
    margin: 20px 0 10px;
    line-height: 1.5rem;
  }

  .subtitle {
    font-size: 0.75rem;
    color: mat.get-color-from-palette(theme.$primary, 500);
    line-height: 0.75rem;
  }
}