*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
}

html,
body {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
}

img {
  display: block;
  border-style: none;
}

[hidden] {
  display: none;
}

[tabindex='-1']:focus {
  outline: 0 !important;
}
